import { render, staticRenderFns } from "./WebmastersScreen.vue?vue&type=template&id=486a0b60&scoped=true&"
import script from "./WebmastersScreen.vue?vue&type=script&lang=js&"
export * from "./WebmastersScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a0b60",
  null
  
)

export default component.exports
<template>

  <div>

    <Header></Header>

    <section class="intro" v-bind:style='{ backgroundImage: "url("+ intro.IntroBGUrl +")" }' id="intro">

      <div class="site__centered">

        <h1 class="intro__title" v-html="$t('webmastersScreen.intro.title')"></h1><!-- /intro__title -->

        <div class="site__btn">
          <a :href="links.webmasterRegistration" class="btn" target="_blank">
            {{ $t('buttons.signUp') }}
          </a>
        </div><!-- /site__btn -->

      </div><!-- /site__centered -->

    </section><!-- /intro -->

    <section class="offers-pub">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate">
          {{ $t('offersPub.title') }}
        </site-title>

        <p class="offers-pub__sub-title">
          {{ $t('offersPub.subTitle') }}
        </p><!-- /offers-pub__sub-title -->

      </div><!-- /site__centered -->

      <div class="offers-pub__wp">

        <span class="offers-pub__arrow"></span>

        <div class="site__centered">

          <h3 class="offers-pub__title" v-html="$t('offersPub.wp.title')"></h3><!-- /offers-pub__title -->

          <div class="offers-pub__list">

            <div class="offers-pub__list-side">

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item1')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item2')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item3')"></div>

            </div><!-- /offers-pub__list-side -->

            <div class="offers-pub__list-side">

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item4')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item5')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.wp.item6')"></div>

            </div><!-- /offers-pub__list-side -->

          </div><!-- /offers-pub__list -->

        </div><!-- /site__centered -->

      </div><!-- /offers-pub__wp -->

      <div class="offers-pub__cab">

        <div class="offers-pub__tel">

          <a href="https://video.distribune.com/dtstatic/distribune_wp_plugin_20200908.zip"
             target="_blank" class="offers-pub__tel-btn">
            <span>{{ $t('buttons.download') }}</span></a><!-- /offers-pub__tel-btn -->

        </div><!-- /offers-pub__tel -->

        <div class="site__centered">

          <h3 class="offers-pub__title" v-html="$t('offersPub.ui.title')"></h3><!-- /offers-pub__title -->

          <div class="offers-pub__list">

            <div class="offers-pub__list-side">

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item1')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item2')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item3')"></div>

            </div><!-- /offers-pub__list-side -->

            <div class="offers-pub__list-side">

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item4')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item5')"></div>

              <div class="offers-pub__list-item" v-html="$t('offersPub.ui.item6')"></div>

            </div><!-- /offers-pub__list-side -->

          </div><!-- /offers-pub__list -->

        </div><!-- /site__centered -->

      </div><!-- /offers-pub__cab -->

    </section><!-- /offers-pub -->

    <section class="load-plugin">

      <div class="site__centered">

        <h2 class="load-plugin__title" v-html="$t('loadPlugin.title')"></h2><!-- /load-plugin__title -->

        <div class="site__btn">
          <a href="https://video.distribune.com/dtstatic/distribune_wp_plugin_20200908.zip"
             target="_blank" class="btn">
            <span>{{ $t('buttons.download') }}</span></a><!-- /btn -->
        </div>

        <div class="load-plugin__txt" v-html="$t('loadPlugin.text')"></div><!-- /load-plugin__txt -->

      </div><!-- /site__centered -->

    </section><!-- /load-plugin -->

    <section class="benefits-pub" ref="slider2">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate">
          {{ $t('benefitsPub.title') }}
        </site-title>

        <div class="benefits-pub__wrap">

          <div class="benefits-pub__side benefits-pub__side_tel">
            <img src="../assets/new/pic/benefits-pub/benefits-pub__tel-2_1.png" width="533" alt=""
                 class="benefits-pub__tel-2 benefits-pub__tel-2_1">
            <img src="../assets/new/pic/benefits-pub/benefits-pub__tel-2_2.png" width="533" alt=""
                 class="benefits-pub__tel-2 benefits-pub__tel-2_2">
            <img src="../assets/new/pic/benefits-pub/benefits-pub__tel-2_3.png" width="533" alt=""
                 class="benefits-pub__tel-2 benefits-pub__tel-2_3">
            <img src="../assets/new/pic/benefits-pub/benefits-pub__tel-2_4.png" width="533" alt=""
                 class="benefits-pub__tel-2 benefits-pub__tel-2_4">
            <img src="../assets/new/pic/benefits-pub/benefits-pub__tel-1.png" width="283" alt=""
                 class="benefits-pub__tel-1">
            <span class="benefits-pub__icon-1"></span>
            <span class="benefits-pub__icon-2"></span>
          </div><!-- /benefits-pub__side -->

          <div class="benefits-pub__side benefits-pub__side_slider">

            <div class="slider-2">

              <div class="swiper-container">

                <div class="swiper-wrapper">

                  <div class="swiper-slide">

                    <div class="slider-2__number">
                      <span class="slider-2__number-item">1</span>
                      <span class="slider-2__number-item"><span>4</span></span>
                    </div><!-- /slider-2__number -->

                    <div class="slider-2__wrap">
                      <div v-html="$t('benefitsPub.text1')"></div>
                    </div><!-- /slider-2__wrap -->

                  </div><!-- /swiper-slide -->

                  <div class="swiper-slide">

                    <div class="slider-2__number">
                      <span class="slider-2__number-item">2</span>
                      <span class="slider-2__number-item"><span>4</span></span>
                    </div><!-- /slider-2__number -->

                    <div class="slider-2__wrap">
                      <div v-html="$t('benefitsPub.text2')"></div>
                    </div><!-- /slider-2__wrap -->

                  </div><!-- /swiper-slide -->

                  <div class="swiper-slide">

                    <div class="slider-2__number">
                      <span class="slider-2__number-item">3</span>
                      <span class="slider-2__number-item"><span>4</span></span>
                    </div><!-- /slider-2__number -->

                    <div class="slider-2__wrap">
                      <div v-html="$t('benefitsPub.text3')"></div>
                    </div><!-- /slider-2__wrap -->

                  </div><!-- /swiper-slide -->

                  <div class="swiper-slide">

                    <div class="slider-2__number">
                      <span class="slider-2__number-item">4</span>
                      <span class="slider-2__number-item"><span>4</span></span>
                    </div><!-- /slider-2__number -->

                    <div class="slider-2__wrap">
                      <div v-html="$t('benefitsPub.text4')"></div>
                    </div><!-- /slider-2__wrap -->

                  </div><!-- /swiper-slide -->

                </div><!-- /swiper-wrapper -->

              </div><!-- /swiper-container -->

              <div class="swiper-pagination"></div>

            </div><!-- /slider-2 -->

          </div><!-- /benefits-pub__side -->

        </div><!-- /benefits-pub__wrap -->

        <div class="site__btn">
          <a :href="links.webmasterRegistration" class="btn" target="_blank">
            {{ $t('buttons.signUp') }}
          </a>
        </div><!-- /site__btn -->

      </div><!-- /site__centered -->

    </section><!-- /benefits-pub -->

    <Footer></Footer>

  </div>

</template>

<script>
import Header from "../components/TheHeader";
import Footer from "../components/TheFooter";
import SiteTitle from "../components/SiteTitile";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import IntroBGUrl from "../assets/new/img/intro/intro__bg_2.jpg";

export default {
  name: "WebmastersScreen",
  components: {
    Header,
    Footer,
    SiteTitle,
  },
  data() {

    return {

      intro: {
        IntroBGUrl
      },
      slider2: {
        wrap: '',
        slider: '',
        container: '',
        pagination: '',
        phoneWrap: '',
        totalSlides: '',
        phoneImages: [],
      }

    }

  },
  methods: {

    setVariables() {
      this.slider2.wrap = this.$refs.slider2;
      this.slider2.slider = this.slider2.wrap.querySelector('.slider-2');
      this.slider2.container = this.slider2.wrap.querySelector('.swiper-container');
      this.slider2.pagination = this.slider2.wrap.querySelector('.swiper-pagination');
      this.slider2.phoneWrap = this.slider2.wrap.querySelector('.benefits-pub__side_tel');
      this.slider2.phoneImages = this.slider2.wrap.querySelectorAll('.benefits-pub__tel-2');
    },

    showPicture(i) {
      for (let k = 0; k < this.slider2.phoneImages.length; k++) {
        this.slider2.phoneImages[k].classList.remove('active');
      }
      i = i <= this.slider2.totalSlides ? i : i - this.slider2.totalSlides;
      const activePic = this.slider2.phoneWrap.querySelector(`.benefits-pub__tel-2_${i}`);
      activePic.classList.add('active');
    },

    initSwiper() {
      let self = this;
      this.setVariables();
      const mySwiper = new Swiper(this.slider2.container, {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        pagination: {
          el: this.slider2.pagination,
          clickable: true,
        },
        on: {
          init: function () {
            self.slider2.totalSlides = this.slides.length - 2;
            self.showPicture(this.activeIndex + 1);
          },
          slideChange: function () {
            self.showPicture(this.activeIndex + 1);
          }
        },
        speed: 800,
      });
      mySwiper.init()
    }
  },
  computed: {
    links() {
      return this.$store.state.static.routePaths;
    }
  },
  mounted() {
    this.initSwiper();
  }
}
</script>

<style scoped>

</style>
